import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

export default function Terms({ data }) {
    return (
        <Layout slug="/terms/" {...data.meta.nodes[0]}>
            <div class="c-page-title">
                <h1>利用規約</h1>
            </div>

            <div class="p-terms">
                <div class="p-section">

                <section>
                    <h2><mark>第1条<small>(本サービスの内容)</small></mark></h2>
                    <p>めざせ！歯科衛生士（以下「本サービス等」という）とは株式会社グッピーズ（以下「弊社」といいます）が提供するインターネット上のウェブマガジンならびに、このサイトに付随する学校情報などの各種情報提供サービスの総称をいいます。</p>
                </section>

                <section>
                    <h2><mark>第2条<small>(本サービス等の利用)</small></mark></h2>
                    <ol>
                        <li>本サービス等をご利用になる方（以下「利用者」といいます）は、本サービス等の利用にあたってめざせ！歯科衛生士利用規約（以下「本規約」といいます）の内容をすべて承諾したものとみなされます。</li>
                        <li>不承諾の意思表示は、本サービス等を利用しないことをもってのみ認められることとします。</li>
                    </ol>
                </section>

                <section>
                    <h2><mark>第3条<small>(利用者の責任)</small></mark></h2>
                    <p>利用者は、自らの意思によって本サービス等を利用し、利用にかかわるすべての責任を負うこととします。</p>
                </section>

                <section>
                    <h2><mark>第4条<small>(利用者の禁止事項)</small></mark></h2>
                    <p>利用者は以下の行為を行ってはならないこととします。</p>
                    <ol>
                        <li>虚偽の情報を登録、告知する行為。</li>
                        <li>第三者又は弊社の著作権、特許権、実用新案権、意匠権、商標権、その他の知的所有権を侵害する行為。および第三者又は弊社の財産、プライバシー、名誉等他人の権利を侵害する行為。</li>
                        <li>第三者又は弊社を誹謗中傷する行為。</li>
                        <li>第三者又は弊社に不利益を与える行為、又はそのおそれのある行為。</li>
                        <li>公序良俗に反する行為、又はそのおそれのある行為。</li>
                        <li>弊社が反社会的勢力と認める団体等に利用者が所属若しくは関係する行為</li>
                        <li>各種法令に違反する行為、又はそのおそれのある行為。</li>
                        <li>本サービス等を利用した営業活動、営利を目的とする情報提供等の行為。</li>
                        <li>本サービス等を通じて入手した情報を、複製、販売、出版その他私的利用の範囲を超えて使用する行為。</li>
                        <li>本サービス等の運営を妨げ、あるいは弊社の信用を毀損するような行為、又はそのおそれのある行為。</li>
                        <li>その他法令に違反する行為。</li>
                    </ol>
                </section>

                <section>
                    <h2><mark>第5条<small>(免責)</small></mark></h2>
                    <ol>
                        <li>弊社は、本サービス等の利用（これらに伴う弊社又は第三者の情報提供行為等を含みます）又は利用できないことから生じる一切の損害（精神的苦痛、求職活動の中断、又はその他の金銭的損失を含む一切の不利益）について、一切責任を負わないこととします。</li>
                        <li>弊社は、本サービス等を通じてアクセスできる第三者医療機関及び事業者が提供するサイトおよびサービスにより生じる一切の損害（精神的苦痛、求職活動の中断、又はその他の金銭的損失を含む一切の不利益）につき、一切責任を負わないこととします。</li>
                        <li>弊社は、天変地異による被害、その他弊社の責めによらない事由による被害が生じた場合、一切責任を負わないこととします。</li>
                        <li>弊社は、本サービス等におけるデータが消去・変更されないことを保証できません。必要なデータは利用者が自己の責任において保存することとします。</li>
                    </ol>
                </section>

                <section>
                    <h2><mark>第6条<small>(提供情報保証の否認)</small></mark></h2>
                    <ol>
                        <li>本サービス等において提供される情報（会社情報等の第三者の情報、広告その他第三者により提供される情報）はその第三者の責任で提供されるものですので、利用者は、提供情報の真実性、合法性、安全性、適切性、有用性、最新性、掲載期限等その他一切の事項について、弊社が何ら保証しないことをご承諾いただき、自己の責任において利用することとします。</li>
                        <li>弊社は、本サービス等の提供に不具合やエラーや障害が生じないこと、本サービス等から得られる情報等が正確なものであること、本サービス等および本サービス等を通じて入手できる情報、掲載期間などが利用者の期待を満たすものであること、以上いずれについても保証しないこととします。</li>
                    </ol>
                </section>

                <section>
                    <h2><mark>第7条<small>(第三者に対する責任)</small></mark></h2>
                    <p>利用者が本サービス等の利用または本サービス等を通じてアクセスできる第三者医療機関及び事業者が提供するサイトおよびサービスにより、第三者との間に生じた紛争、損害はすべて利用者の責任において解決し、弊社は何らの責任を負わず、介入しないこととします。</p>
                </section>

                <section>
                    <h2><mark>第8条<small>(反社会的勢力の排除)</small></mark></h2>
                    <ol>
                        <li>利用者が反社会的勢力（暴力団、暴力団員、暴力団関係企業・団体またはその関係者、その他反社会勢力）でないことを誓約するものとします。また暴力的行為、詐術・脅迫行為、業務妨害行為等違法行為を行わないことを、現在および将来にわたって表明するものとします。</li>
                        <li>利用者が反社会的勢力であることが判明した場合、その他利用者が前項の誓約、表明に反した場合は、当社は利用者に対する何らの催告なく、直ちに利用者への本サービスの提供を終了することができるものとします。これにより利用者に生じた一切の損害について、当社は何らの責任も負わないものとします。</li>
                    </ol>
                </section>

                <section>
                    <h2><mark>第9条<small>(損害賠償)</small></mark></h2>
                    <p>利用者が本規約に違反し弊社に対し損害を与えた場合、利用者は弊社に対し直接・間接を問わず一切の損害の賠償義務を負うこととします。</p>
                </section>

                <section>
                    <h2><mark>第10条<small>(プライバシーポリシーについて)</small></mark></h2>
                    <p><a href="https://www.guppy.co.jp/policy/" target="_blank">「プライバシーポリシー」</a>に規定します。</p>
                </section>

                <section>
                    <h2><mark>第11条<small>(準拠法及び管轄)</small></mark></h2>
                    <p>本規約は日本法を準拠法とし、本サービス等の利用および本規約に関して生じる一切の紛争については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</p>
                </section>

                <section>
                    <h2><mark>第12条</mark></h2>
                    <p>本規約は2021年9月1日から実施します。</p>
                </section>

                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        meta: allMeta(filter: {slug: {eq: "/terms/"}}) {
            nodes {
                title
                description
                keywords
            }
        }
    }
`